import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as indexs3XnOKm92nMeta } from "/app/pages/admin/blog/index.vue?macro=true";
import { default as index63YNrETJ7ZMeta } from "/app/pages/admin/catalog-references/index.vue?macro=true";
import { default as change_45logiouSlPma9qMeta } from "/app/pages/admin/catalog/change-log.vue?macro=true";
import { default as indexw7gHA70C9pMeta } from "/app/pages/admin/catalog/index.vue?macro=true";
import { default as mebDO5Pay1B9Meta } from "/app/pages/admin/catalog/me.vue?macro=true";
import { default as indexOGoyqUcd4PMeta } from "/app/pages/admin/faq/index.vue?macro=true";
import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as loginjuBiVElJesMeta } from "/app/pages/admin/login.vue?macro=true";
import { default as indexRgGfAkY0MMMeta } from "/app/pages/admin/pages/index.vue?macro=true";
import { default as indexXjiWyg4abpMeta } from "/app/pages/admin/references/index.vue?macro=true";
import { default as index6CKRkvYDj5Meta } from "/app/pages/admin/tags/index.vue?macro=true";
import { default as indexKzNkAUINzQMeta } from "/app/pages/admin/texts/index.vue?macro=true";
import { default as unauthorizedKT3TuNtjIEMeta } from "/app/pages/admin/unauthorized.vue?macro=true";
import { default as indexYm9dzWmM2AMeta } from "/app/pages/admin/users/index.vue?macro=true";
import { default as verify_45emailSW461DJyQKMeta } from "/app/pages/admin/verify-email.vue?macro=true";
import { default as indexvSQmcOZmizMeta } from "/app/pages/admin/vocabulary/index.vue?macro=true";
import { default as indexFTwI8RiIAkMeta } from "/app/pages/admin/zdarma/index.vue?macro=true";
import { default as _91_46_46_46slug_93jK3Pyj8dSOMeta } from "/app/pages/blog/[...slug].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_939Gp5g4x6jnMeta } from "/app/pages/katalog/[...slug].vue?macro=true";
import { default as kontaktzJWjXi4cLvMeta } from "/app/pages/kontakt.vue?macro=true";
import { default as networkingph2x8gsdyAMeta } from "/app/pages/networking.vue?macro=true";
import { default as _91_46_46_46slug_93xuf2VKbD91Meta } from "/app/pages/poradenstvi/[...slug].vue?macro=true";
import { default as registrace_45asistentekgpwtcxg0QsMeta } from "/app/pages/registrace-asistentek.vue?macro=true";
import { default as _91_46_46_46slug_936kE8eh5anMMeta } from "/app/pages/slovnik/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93tu0picYvsXMeta } from "/app/pages/zdarma/[...slug].vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "admin-blog",
    path: "/admin/blog",
    meta: indexs3XnOKm92nMeta || {},
    component: () => import("/app/pages/admin/blog/index.vue")
  },
  {
    name: "admin-catalog-references",
    path: "/admin/catalog-references",
    meta: index63YNrETJ7ZMeta || {},
    component: () => import("/app/pages/admin/catalog-references/index.vue")
  },
  {
    name: "admin-catalog-change-log",
    path: "/admin/catalog/change-log",
    meta: change_45logiouSlPma9qMeta || {},
    component: () => import("/app/pages/admin/catalog/change-log.vue")
  },
  {
    name: "admin-catalog",
    path: "/admin/catalog",
    meta: indexw7gHA70C9pMeta || {},
    component: () => import("/app/pages/admin/catalog/index.vue")
  },
  {
    name: "admin-catalog-me",
    path: "/admin/catalog/me",
    meta: mebDO5Pay1B9Meta || {},
    component: () => import("/app/pages/admin/catalog/me.vue")
  },
  {
    name: "admin-faq",
    path: "/admin/faq",
    meta: indexOGoyqUcd4PMeta || {},
    component: () => import("/app/pages/admin/faq/index.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexi8nCATZISJMeta || {},
    component: () => import("/app/pages/admin/index.vue")
  },
  {
    name: "admin-login",
    path: "/admin/login",
    meta: loginjuBiVElJesMeta || {},
    component: () => import("/app/pages/admin/login.vue")
  },
  {
    name: "admin-pages",
    path: "/admin/pages",
    meta: indexRgGfAkY0MMMeta || {},
    component: () => import("/app/pages/admin/pages/index.vue")
  },
  {
    name: "admin-references",
    path: "/admin/references",
    meta: indexXjiWyg4abpMeta || {},
    component: () => import("/app/pages/admin/references/index.vue")
  },
  {
    name: "admin-tags",
    path: "/admin/tags",
    meta: index6CKRkvYDj5Meta || {},
    component: () => import("/app/pages/admin/tags/index.vue")
  },
  {
    name: "admin-texts",
    path: "/admin/texts",
    meta: indexKzNkAUINzQMeta || {},
    component: () => import("/app/pages/admin/texts/index.vue")
  },
  {
    name: "admin-unauthorized",
    path: "/admin/unauthorized",
    meta: unauthorizedKT3TuNtjIEMeta || {},
    component: () => import("/app/pages/admin/unauthorized.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: indexYm9dzWmM2AMeta || {},
    component: () => import("/app/pages/admin/users/index.vue")
  },
  {
    name: "admin-verify-email",
    path: "/admin/verify-email",
    meta: verify_45emailSW461DJyQKMeta || {},
    component: () => import("/app/pages/admin/verify-email.vue")
  },
  {
    name: "admin-vocabulary",
    path: "/admin/vocabulary",
    meta: indexvSQmcOZmizMeta || {},
    component: () => import("/app/pages/admin/vocabulary/index.vue")
  },
  {
    name: "admin-zdarma",
    path: "/admin/zdarma",
    meta: indexFTwI8RiIAkMeta || {},
    component: () => import("/app/pages/admin/zdarma/index.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug(.*)*",
    component: () => import("/app/pages/blog/[...slug].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "katalog-slug",
    path: "/katalog/:slug(.*)*",
    component: () => import("/app/pages/katalog/[...slug].vue")
  },
  {
    name: "kontakt",
    path: "/kontakt",
    component: () => import("/app/pages/kontakt.vue")
  },
  {
    name: "networking",
    path: "/networking",
    component: () => import("/app/pages/networking.vue")
  },
  {
    name: "poradenstvi-slug",
    path: "/poradenstvi/:slug(.*)*",
    component: () => import("/app/pages/poradenstvi/[...slug].vue")
  },
  {
    name: "registrace-asistentek",
    path: "/registrace-asistentek",
    component: () => import("/app/pages/registrace-asistentek.vue")
  },
  {
    name: "slovnik-slug",
    path: "/slovnik/:slug(.*)*",
    component: () => import("/app/pages/slovnik/[...slug].vue")
  },
  {
    name: "zdarma-slug",
    path: "/zdarma/:slug(.*)*",
    component: () => import("/app/pages/zdarma/[...slug].vue")
  }
]